<template>
    <v-dialog v-model="shows" :width="width" content-class="v-dialog--default">
        <template #activator="{attrs,on}">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card class="v-card--default">
            <slot v-bind="{ close }" />
            <v-hover v-slot="{ hover }">
                <v-btn absolute top right small icon color="grey-16" @click="close" :class="{ 'v-dialog--default__close': true, hover }">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-hover>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        value: { type: Boolean, default: false },
        width: { type: String, default: "670" },
        showsActions: { type: Boolean, default: false },
    },
    data: () => ({
        shows: false,
    }),
    watch: {
        value(shows) {
            this.shows = shows;
        },
        shows(shows) {
            this.$emit("input", shows);
            if (shows) this.$emit("open");
            else this.$emit("close");
        },
    },
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style scoped>
</style>
