<template>
    <client-page>
        <template v-slot:subHead>
            <sub-visual title="Download" tabActive="CI" src="/images/sub/sv-story-values-ci.png"></sub-visual>
        </template>

        <!-- <sub-depth-tab-wrap>
            <story-values-chips />
        </sub-depth-tab-wrap> -->

        <sub-section class="page-section--first">
            <div class="tit-txt-wrap text-center">
                <div class="tit-wrap">
                    <h2 class="tit">Group for the Popularization of <br class="d-none d-md-block"/>Domestic Implants</h2>
                </div>
                <p class="tit-txt">CI is a company specializing in the production of implant products that Dentium's top quality, <br class="d-none d-md-block"/>top dental professionals participate in and produce.</p>
            </div>

            <div class="img-wrap">
                <v-img max-width="1200" src="/images/sub/story/values/ci-img.png" />
            </div>
        </sub-section>

        <sub-section class="pt-0">
            <div class="tit-wrap tit-wrap--hyphen">
                <h2 class="tit tit--xs">Corporate Identity</h2>
            </div>

            <div class="img-wrap">
                <v-img max-width="1200" src="/images/sub/story/values/ci-img2.jpg" />
            </div>
            <div class="mt-40 mt-md-60">
                <div class="v-btn--group">
                    <v-btn small outlined download href="/file/dentium-logo.ai" color="grey-d" class="w-160px">AI Download<i class="icon icon-down ml-6"></i></v-btn>
                    <v-btn small outlined download href="/file/dentium-logo.png" color="grey-d" class="w-160px">PNG Download<i class="icon icon-down ml-6"></i></v-btn>
                </div>
            </div>
        </sub-section>

        <sub-section class="page-section--last pt-0">
            <div class="tit-wrap tit-wrap--hyphen">
                <h2 class="tit tit--xs">Color System</h2>
            </div>

            <div class="font-size-12 font-size-md-14 grey-a--text mb-8 mb-md-10">
                <v-row class="mx-n10 mx-lg-n20">
                    <v-col cols="6" md="4" lg="" class="px-10 py-0 px-lg-20">Primary</v-col>
                    <v-col cols="auto" class="d-none d-lg-block px-10 py-0 px-lg-20 ml-n1"></v-col>
                    <v-col cols="6" md="4" lg="" class="px-10 py-0 px-lg-20">Secondary</v-col>
                    <v-col cols="6" md="4" lg="" class="d-none d-lg-block px-10 py-0 px-lg-20"></v-col>
                    <v-col cols="6" md="4" lg="" class="d-none d-lg-block px-10 py-0 px-lg-20"></v-col>
                    <v-col cols="6" md="4" lg="" class="d-none d-lg-block px-10 py-0 px-lg-20"></v-col>
                </v-row>
            </div>
            <v-row class="ma-n10 ma-lg-n20">
                <v-col cols="6" md="4" lg="" class="pa-10 pa-lg-20">
                    <v-sheet color="primary"><v-responsive :aspect-ratio="1/1"></v-responsive></v-sheet>
                    <div class="mt-14 mt-md-20">
                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text">Light Green</div>
                        <div class="font-size-14 font-size-md-16 mt-10 mt-md-14">
                            <div class="grey-6--text"><span class="grey-a--text pr-6 pr-md-10">CMYK</span>34 / 6 / 95 / 0</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">RGB</span>194 / 213 / 0</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">HEX</span>#c2d500</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="auto" class="d-none d-lg-block pa-10 pa-lg-20 ml-n1"><v-divider vertical /></v-col>
                <v-col cols="6" md="4" lg="" class="pa-10 pa-lg-20">
                    <v-sheet color="secondary"><v-responsive :aspect-ratio="1/1"></v-responsive></v-sheet>
                    <div class="mt-14 mt-md-20">
                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text">Green</div>
                        <div class="font-size-14 font-size-md-16 mt-10 mt-md-14">
                            <div class="grey-6--text"><span class="grey-a--text pr-6 pr-md-10">CMYK</span>46 / 11 / 89 / 0</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">RGB</span>162 / 196 / 56</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">HEX</span>#a2c438</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" md="4" lg="" class="pa-10 pa-lg-20">
                    <v-sheet color="grey-90"><v-responsive :aspect-ratio="1/1"></v-responsive></v-sheet>
                    <div class="mt-14 mt-md-20">
                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text">Light Gray</div>
                        <div class="font-size-14 font-size-md-16 mt-10 mt-md-14">
                            <div class="grey-6--text"><span class="grey-a--text pr-6 pr-md-10">CMYK</span>50 / 41 / 39 / 0</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">RGB</span>144 / 144 / 144</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">HEX</span>#909090</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" md="4" lg="" class="pa-10 pa-lg-20">
                    <v-sheet color="grey-6"><v-responsive :aspect-ratio="1/1"></v-responsive></v-sheet>
                    <div class="mt-14 mt-md-20">
                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text">Dark Gray</div>
                        <div class="font-size-14 font-size-md-16 mt-10 mt-md-14">
                            <div class="grey-6--text"><span class="grey-a--text pr-6 pr-md-10">CMYK</span>67 / 59 / 56 / 6</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">RGB</span>102 / 102 / 102</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">HEX</span>#666666</div>
                        </div>
                    </div>
                </v-col>
                <v-col cols="6" md="4" lg="" class="pa-10 pa-lg-20">
                    <v-sheet color="grey-16"><v-responsive :aspect-ratio="1/1"></v-responsive></v-sheet>
                    <div class="mt-14 mt-md-20">
                        <div class="font-size-16 font-size-md-18 font-weight-medium grey-16--text">Black</div>
                        <div class="font-size-14 font-size-md-16 mt-10 mt-md-14">
                            <div class="grey-6--text"><span class="grey-a--text pr-6 pr-md-10">CMYK</span>86 / 81 / 81 / 68</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">RGB</span>22 / 22 / 22</div>
                            <div class="grey-6--text mt-2"><span class="grey-a--text pr-6 pr-md-10">HEX</span>#161616</div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </sub-section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";
import SubSection from "@/components/client/sub/sub-section.vue";
import StoryValuesChips from "@/components/client/sub/story-values-chips.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        SubDepthTabWrap,
        SubSection,
        StoryValuesChips,
    },
    data () {
        return {
        }
    },
    mounted() {
    },
    methods: {
    },
};
</script>

<style></style>
