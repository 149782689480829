<template>
    <v-tab-item :value="software?._id" eager>
        <v-container>
            <v-row>
                <v-col cols="12" md="5">
                    <v-img contain :aspect-ratio="1 / 1" :src="thumbSrc" class="border">
                        <v-overlay v-if="!thumbSrc" absolute z-index="0">
                            <v-icon large>mdi-image-broken</v-icon>
                        </v-overlay>
                    </v-img>
                </v-col>
                <v-col cols="12" md="7">
                    <div class="pt-20 pt-md-0 pl-lg-40">
                        <v-divider class="border-2 secondary" />
                        <div class="py-20 py-md-30">
                            <h3 class="tit tit--xs">{{ software?.name }}</h3>
                        </div>
                        <v-divider />

                        <div class="v-btn-group--bottom">
                            <v-row class="row--small">
                                <v-col cols="6" sm="auto">
                                    <member-donwload-link :href="downloadHref" small block outlined color="grey-d" class="w-md-200px"> S/W Download<i class="icon icon-down ml-6"></i> </member-donwload-link>
                                </v-col>
                            </v-row>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <div class="pt-60 pt-md-80 pt-lg-100">
                <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
                    <h2 class="tit tit--xs">Video</h2>
                </div>

                <v-dialog v-model="showsDialog" fullscreen transition="fade-transition">
                    <template #activator="{ attrs, on }">
                        <v-img v-bind="attrs" v-on="on" :aspect-ratio="1200 / 675" class="cursor-pointer">
                            <v-overlay absolute z-index="0" style="pointer-events:none">
                                <video :src="videoSrc" height="675" />
                            </v-overlay>
                            <v-overlay absolute z-index="0" color="transparent">
                                <v-icon x-large>mdi-play-circle-outline</v-icon>
                            </v-overlay>
                        </v-img>
                    </template>
                    <v-overlay>
                        <v-container>
                            <div class="v-dialog--fullscreen__close">
                                <v-btn fab tile @click="showsDialog = false">
                                    <v-icon x-large>mdi-close</v-icon>
                                </v-btn>
                            </div>
                            <div class="line-height-0">
                                <video :src="videoSrc" width="100%" controls />
                            </div>
                        </v-container>
                    </v-overlay>
                </v-dialog>
            </div>

            <div class="pt-60 pt-md-80 pt-lg-100">
                <div class="tit-wrap tit-wrap--hyphen mb-16 mb-md-30">
                    <h2 class="tit tit--xs">Detail</h2>
                </div>
                <div v-html="software?.desc" />
            </div>
        </v-container>
    </v-tab-item>
</template>

<script>
import MemberDonwloadLink from "../academy/member-donwload-link.vue";

export default {
    components: {
        MemberDonwloadLink,
    },
    props: {
        software: { type: Object, default: () => ({}) },
    },
    data: () => ({
        showsDialog: false,
    }),
    computed: {
        thumbSrc() {
            return this.software?.thumb?.url;
        },
        videoSrc() {
            return this.software?.video?.url;
        },
        downloadHref() {
            return this.software?.files?.[0]?.url;
        },
    },
};
</script>

<style></style>
