var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Download",
            "tabActive": "Digital Guide S/W",
            "src": "/images/sub/sv-digital-center-download.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.softwares.length ? [_c('sub-depth-tab-wrap', [_c('v-container', [_c('download-list', _vm._b({}, 'download-list', {
    softwares: _vm.softwares
  }, false))], 1)], 1), _c('v-tabs-items', {
    attrs: {
      "value": _vm._software
    }
  }, [_vm._l(_vm.softwares, function (software) {
    return [_c('download-view', _vm._b({
      key: software === null || software === void 0 ? void 0 : software._id
    }, 'download-view', {
      software
    }, false))];
  })], 2)] : [_c('v-container', [_c('v-card-title', {
    staticClass: "justify-center font-size-24 font-weight-light grey-90--text"
  }, [_vm._v(" No software has been uploaded, yet. ")])], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }