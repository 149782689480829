var render = function render(){
  var _vm$software, _vm$software2, _vm$software3;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-tab-item', {
    attrs: {
      "value": (_vm$software = _vm.software) === null || _vm$software === void 0 ? void 0 : _vm$software._id,
      "eager": ""
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_c('v-img', {
    staticClass: "border",
    attrs: {
      "contain": "",
      "aspect-ratio": 1 / 1,
      "src": _vm.thumbSrc
    }
  }, [!_vm.thumbSrc ? _c('v-overlay', {
    attrs: {
      "absolute": "",
      "z-index": "0"
    }
  }, [_c('v-icon', {
    attrs: {
      "large": ""
    }
  }, [_vm._v("mdi-image-broken")])], 1) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "pt-20 pt-md-0 pl-lg-40"
  }, [_c('v-divider', {
    staticClass: "border-2 secondary"
  }), _c('div', {
    staticClass: "py-20 py-md-30"
  }, [_c('h3', {
    staticClass: "tit tit--xs"
  }, [_vm._v(_vm._s((_vm$software2 = _vm.software) === null || _vm$software2 === void 0 ? void 0 : _vm$software2.name))])]), _c('v-divider'), _c('div', {
    staticClass: "v-btn-group--bottom"
  }, [_c('v-row', {
    staticClass: "row--small"
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "sm": "auto"
    }
  }, [_c('member-donwload-link', {
    staticClass: "w-md-200px",
    attrs: {
      "href": _vm.downloadHref,
      "small": "",
      "block": "",
      "outlined": "",
      "color": "grey-d"
    }
  }, [_vm._v(" S/W Download"), _c('i', {
    staticClass: "icon icon-down ml-6"
  })])], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "pt-60 pt-md-80 pt-lg-100"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Video")])]), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "transition": "fade-transition"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_c('v-img', _vm._g(_vm._b({
          staticClass: "cursor-pointer",
          attrs: {
            "aspect-ratio": 1200 / 675
          }
        }, 'v-img', attrs, false), on), [_c('v-overlay', {
          staticStyle: {
            "pointer-events": "none"
          },
          attrs: {
            "absolute": "",
            "z-index": "0"
          }
        }, [_c('video', {
          attrs: {
            "src": _vm.videoSrc,
            "height": "675"
          }
        })]), _c('v-overlay', {
          attrs: {
            "absolute": "",
            "z-index": "0",
            "color": "transparent"
          }
        }, [_c('v-icon', {
          attrs: {
            "x-large": ""
          }
        }, [_vm._v("mdi-play-circle-outline")])], 1)], 1)];
      }
    }]),
    model: {
      value: _vm.showsDialog,
      callback: function ($$v) {
        _vm.showsDialog = $$v;
      },
      expression: "showsDialog"
    }
  }, [_c('v-overlay', [_c('v-container', [_c('div', {
    staticClass: "v-dialog--fullscreen__close"
  }, [_c('v-btn', {
    attrs: {
      "fab": "",
      "tile": ""
    },
    on: {
      "click": function ($event) {
        _vm.showsDialog = false;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("mdi-close")])], 1)], 1), _c('div', {
    staticClass: "line-height-0"
  }, [_c('video', {
    attrs: {
      "src": _vm.videoSrc,
      "width": "100%",
      "controls": ""
    }
  })])])], 1)], 1)], 1), _c('div', {
    staticClass: "pt-60 pt-md-80 pt-lg-100"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen mb-16 mb-md-30"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Detail")])]), _c('div', {
    domProps: {
      "innerHTML": _vm._s((_vm$software3 = _vm.software) === null || _vm$software3 === void 0 ? void 0 : _vm$software3.desc)
    }
  })])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }