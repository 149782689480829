var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    attrs: {
      "disableScrollTop": ""
    },
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Download",
            "tabActive": _vm.CODES[_vm.code] || 'Catalog',
            "src": "/images/sub/sv-product-catalogs.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('v-container', {
    staticClass: "font-spoqa"
  }, [_c('v-row', {
    staticClass: "my-n12 my-md-n20"
  }, _vm._l(_vm.catalogues, function (catalogue, idx) {
    var _catalogue$file, _catalogue$category, _catalogue$file2;
    return _c('v-col', {
      key: idx,
      staticClass: "py-12 py-md-20",
      attrs: {
        "cols": "12",
        "sm": "6",
        "md": "4",
        "lg": "3"
      }
    }, [_c('v-card', {
      attrs: {
        "tile": "",
        "flat": "",
        "href": catalogue === null || catalogue === void 0 ? void 0 : (_catalogue$file = catalogue.file) === null || _catalogue$file === void 0 ? void 0 : _catalogue$file.url,
        "target": "_blank"
      }
    }, [_c('v-img', {
      attrs: {
        "src": catalogue === null || catalogue === void 0 ? void 0 : catalogue.thumb,
        "aspect-ratio": 282 / 400
      }
    }, [_c('v-fade-transition', [_c('v-overlay', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !(catalogue !== null && catalogue !== void 0 && catalogue.thumb),
        expression: "!catalogue?.thumb"
      }],
      attrs: {
        "absolute": ""
      }
    }, [_c('v-icon', [_vm._v("mdi-image")])], 1)], 1)], 1)], 1), _c('div', {
      staticClass: "mt-16 mt-md-26"
    }, [_c('div', {
      staticClass: "font-size-14 font-size-md-16 grey-90--text mb-4"
    }, [_vm._v(_vm._s(catalogue === null || catalogue === void 0 ? void 0 : (_catalogue$category = catalogue.category) === null || _catalogue$category === void 0 ? void 0 : _catalogue$category.name))]), _c('div', {
      staticClass: "font-size-16 font-size-md-18 font-weight-medium catalogs-tit"
    }, [_vm._v(_vm._s(catalogue === null || catalogue === void 0 ? void 0 : catalogue.name))])]), _c('div', {
      staticClass: "mt-16 mt-md-26"
    }, [_c('v-btn', {
      staticClass: "w-md-200px font-pop",
      attrs: {
        "small": "",
        "block": "",
        "outlined": "",
        "color": "grey-d",
        "download": "",
        "href": catalogue === null || catalogue === void 0 ? void 0 : (_catalogue$file2 = catalogue.file) === null || _catalogue$file2 === void 0 ? void 0 : _catalogue$file2.url
      },
      on: {
        "click": function ($event) {
          var _catalogue$file3;
          return _vm.download(`${catalogue === null || catalogue === void 0 ? void 0 : (_catalogue$file3 = catalogue.file) === null || _catalogue$file3 === void 0 ? void 0 : _catalogue$file3.url}`, `${catalogue === null || catalogue === void 0 ? void 0 : catalogue.name}`);
        }
      }
    }, [_vm._v("Catalog Download"), _c('i', {
      staticClass: "icon icon-down ml-6"
    })])], 1)], 1);
  }), 1), _c('pagination-component', {
    attrs: {
      "count": _vm.pageCount,
      "value": _vm.page
    },
    on: {
      "change": function (page) {
        return _vm.$router.replace({
          query: Object.assign({}, _vm.$route.query, {
            page
          })
        });
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }