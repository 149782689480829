<template>
    <v-btn v-bind="$attrs" @click.prevent="go">
        <slot />
        <login-to-continue ref="LoginToContinue" />
    </v-btn>
</template>

<script>
import api from "@/api";

import LoginToContinue from "./login-to-continue.vue";

export default {
    components: {
        LoginToContinue,
    },
    props: {
        href: { type: String, default: "/" },
    },
    computed: {
        hasUserLoggedIn() {
            return !!this.$store.state.payload?._user;
        },
    },
    methods: {
        async go() {
            if (!this.hasUserLoggedIn) {
                this.$refs["LoginToContinue"].shows = true;
                return;
            } else {
                if (this.href) await api.downloadMemberResource(this.href);
            }
        },
    },
};
</script>

<style></style>
