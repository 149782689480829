var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('chip-link-group', _vm._b({
    attrs: {
      "tabActive": _vm.tabActive
    }
  }, 'chip-link-group', {
    chips: _vm.chips
  }, false));

}
var staticRenderFns = []

export { render, staticRenderFns }