var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "width": _vm.width,
      "content-class": "v-dialog--default"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card', {
    staticClass: "v-card--default"
  }, [_vm._t("default", null, null, {
    close: _vm.close
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref2) {
        var hover = _ref2.hover;
        return [_c('v-btn', {
          class: {
            'v-dialog--default__close': true,
            hover
          },
          attrs: {
            "absolute": "",
            "top": "",
            "right": "",
            "small": "",
            "icon": "",
            "color": "grey-16"
          },
          on: {
            "click": _vm.close
          }
        }, [_c('v-icon', [_vm._v("mdi-close")])], 1)];
      }
    }])
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }