var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    staticClass: "row--x-small",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, _vm._l(_vm.items, function (_ref) {
    var _id = _ref._id,
      name = _ref.name,
      to = _ref.to;
    return _c('v-col', {
      key: _id,
      attrs: {
        "cols": "auto"
      }
    }, [_c('v-chip', _vm._b({
      attrs: {
        "exact-path": "",
        "rounded": "",
        "color": "transparent",
        "active-class": "secondary white--text"
      }
    }, 'v-chip', {
      to
    }, false), [_vm._v(_vm._s(name))])], 1);
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }