<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Download" :tabActive="CODES[code] || 'Catalog'" src="/images/sub/sv-product-catalogs.png"></sub-visual>
        </template>

        <v-container class="font-spoqa">
            <v-row class="my-n12 my-md-n20">
                <v-col v-for="(catalogue, idx) in catalogues" :key="idx" cols="12" sm="6" md="4" lg="3" class="py-12 py-md-20">
                    <v-card tile flat :href="catalogue?.file?.url" target="_blank">
                        <v-img :src="catalogue?.thumb" :aspect-ratio="282 / 400">
                            <v-fade-transition>
                                <v-overlay v-show="!catalogue?.thumb" absolute>
                                    <v-icon>mdi-image</v-icon>
                                </v-overlay>
                            </v-fade-transition>
                        </v-img>
                    </v-card>
                    <div class="mt-16 mt-md-26">
                        <div class="font-size-14 font-size-md-16 grey-90--text mb-4">{{ catalogue?.category?.name }}</div>
                        <div class="font-size-16 font-size-md-18 font-weight-medium catalogs-tit">{{ catalogue?.name }}</div>
                    </div>
                    <div class="mt-16 mt-md-26">
                        <!-- :href="" -->
                        <v-btn small block outlined color="grey-d" download :href="catalogue?.file?.url" @click="download(`${catalogue?.file?.url}`, `${catalogue?.name}`)" class="w-md-200px font-pop">Catalog Download<i class="icon icon-down ml-6"></i></v-btn>
                    </div>
                </v-col>
            </v-row>

            <pagination-component :count="pageCount" :value="page" @change="(page) => $router.replace({ query: { ...$route.query, page } })" />
        </v-container>
    </client-page>
</template>

<script>
import api from "@/api";
import download from "downloadjs";
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import PaginationComponent from "@/components/client/control/pagination-component.vue";

const CODES = {
    ["abstracts"]: "Abstract",
};

export default {
    components: {
        ClientPage,
        SubVisual,
        PaginationComponent,
    },
    props: {
        code: { type: String, default: null },
    },
    data: () => ({
        limit: 8,

        summary: { totalCount: 0 },
        catalogues: [],
        categories: [],

        CODES,
    }),
    computed: {
        page() {
            return +this.$route.query.page || 1;
        },
        skip() {
            return (this.page - 1) * this.limit;
        },
        pageCount() {
            return Math.ceil(this.summary.totalCount / this.limit) || 1;
        },
        searchKey() {
            return this.$route.query.searchKey;
        },
        searchValue() {
            return this.$route.query.searchValue;
        },
        items() {
            return this.catalogues;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        page() {
            this.search();
        },
        code() {
            this.search();
        },
    },
    methods: {
        async init() {
            this.search();
        },
        async search() {
            const { skip, limit, code, searchKey, searchValue } = this;
            const { summary, catalogues } = await api.v1.shop.catalogues.gets({
                headers: { skip, limit },
                params: { code, searchKey, searchValue },
            });
            this.summary = summary;
            this.catalogues = catalogues;
        },
        async putViews(item) {
            const { catalogue } = await api.v1.shop.catalogues.putViews(item);
            this.update(catalogue);
        },
        async update(item) {
            const index = this.catalogues.findIndex(({ _id }) => _id == item._id);
            if (index > -1) this.catalogues.splice(index, 1, item);
            else {
                this.catalogues = [item, ...this.catalogues];
                this.summary.totalCount += 1;
            }
        },
        download(path, rename) {
            api.getResourcePdf(path).then((file) => download(file, rename));
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep td.text-align-left {
    text-align: left !important;
}
.catalogs-tit {
    height: calc(1.35em * 2);
    line-height: 1.35;
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
</style>
