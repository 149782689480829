<template>
    <client-page disableScrollTop>
        <template v-slot:subHead>
            <sub-visual title="Download" tabActive="Digital Guide S/W" src="/images/sub/sv-digital-center-download.png"></sub-visual>
        </template>
        <template v-if="softwares.length">
            <sub-depth-tab-wrap>
                <v-container>
                    <download-list v-bind="{ softwares }" />
                </v-container>
            </sub-depth-tab-wrap>
            <v-tabs-items :value="_software">
                <template v-for="software in softwares">
                    <download-view :key="software?._id" v-bind="{ software }" />
                </template>
            </v-tabs-items>
        </template>
        <template v-else>
            <v-container>
                <v-card-title class="justify-center font-size-24 font-weight-light grey-90--text"> No software has been uploaded, yet. </v-card-title>
            </v-container>
        </template>
    </client-page>
</template>

<script>
import api from "@/api";

import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisual from "@/components/client/sub/sub-visual.vue";
import DownloadList from "@/components/client/digital-center/download-list.vue";
import DownloadView from "@/components/client/digital-center/download-view.vue";
import SubDepthTabWrap from "@/components/client/sub/sub-depth-tab-wrap.vue";

export default {
    components: {
        ClientPage,
        SubVisual,
        DownloadList,
        DownloadView,
        SubDepthTabWrap,
    },
    props: {
        _software: { type: String },
    },
    data: () => ({
        softwares: [],
    }),
    mounted() {
        this.init();
    },
    methods: {
        async init() {
            this.softwares = (await api.v1.membership.softwares.gets())?.softwares;
        },
    },
};
</script>

<style scoped></style>
