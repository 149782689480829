<template>
    <academy-dialog v-model="shows" width="480" ref="dialog">
        <template #activator="{ attrs, on }">
            <slot name="activator" v-bind="{ attrs, on }" />
        </template>
        <v-card-title class="justify-center pt-48"> You must be logged in to continue. </v-card-title>
        <v-card-actions class="pt-0 justify-center">
            <v-btn outlined color="grey" @click="close">Close</v-btn>
            <v-btn color="primary" to="/login">Login</v-btn>
        </v-card-actions>
    </academy-dialog>
</template>

<script>
import AcademyDialog from "./academy-dialog.vue";
export default {
    components: {
        AcademyDialog,
    },
    props: {
        lecture: { type: Object, default: () => ({}) },
        unit: { type: Object, default: () => ({}) },
    },
    data: () => ({
        shows: false,
        ready: false,
    }),
    methods: {
        close() {
            this.shows = false;
        },
    },
};
</script>

<style></style>
