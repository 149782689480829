<template>
    <chip-link-group v-bind="{ chips }" :tabActive="tabActive" />
</template>

<script>
import ChipLinkGroup from "../dumb/chip-link-group.vue";

const chips = [
    { text: "Overview", value: "overview", to: "/story/values/overview" },
    // { text: "History", value: "history", to: "/story/values/history" },
    // { text: "Vision", value: "vision", to: "/story/values/vision/trust" },
    { text: "R&D", value: "rnd", to: "/story/values/rnd" },
    { text: "Live Surgery", value: "live", to: "/story/values/live" },
];
export default {
    props: {
        tabActive: { type: String, default: null },
    },
    components: { ChipLinkGroup },
    data: () => ({
        chips,
    }),
};
</script>

<style></style>
