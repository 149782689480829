<template>
    <v-row align="center" justify="center" class="row--x-small">
        <v-col v-for="{ _id, name, to } in items" :key="_id" cols="auto">
            <v-chip exact-path rounded color="transparent" v-bind="{ to }" active-class="secondary white--text">{{ name }}</v-chip>
        </v-col>
    </v-row>
</template>

<script>
import ChipLinkGroup from "../dumb/chip-link-group.vue";

const basePath = "/download-center/guides";

export default {
    components: { ChipLinkGroup },
    props: {
        softwares: { type: Array, default: () => [] },
    },
    computed: {
        items() {
            return this.softwares.map(({ _id, name }) => ({ _id, name, to: `${basePath}/${_id}` }));
        },
    },
    mounted() {
        this.syncPath();
    },
    watch: {
        "$route.path"() {
            this.syncPath();
        },
        items() {
            this.syncPath();
        },
    },
    methods: {
        syncPath() {
            if (this.$route.path == basePath) {
                let { _id } = this.items?.[0] || {};
                if (!!_id) this.$router.replace({ path: `${basePath}/${_id}` });
            }
        },
    },
};
</script>

<style scoped>
.v-application .theme--light.v-chip {
    color: var(--default-light-text-color);
}
</style>
