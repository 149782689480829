var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('academy-dialog', {
    ref: "dialog",
    attrs: {
      "width": "480"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var attrs = _ref.attrs,
          on = _ref.on;
        return [_vm._t("activator", null, null, {
          attrs,
          on
        })];
      }
    }], null, true),
    model: {
      value: _vm.shows,
      callback: function ($$v) {
        _vm.shows = $$v;
      },
      expression: "shows"
    }
  }, [_c('v-card-title', {
    staticClass: "justify-center pt-48"
  }, [_vm._v(" You must be logged in to continue. ")]), _c('v-card-actions', {
    staticClass: "pt-0 justify-center"
  }, [_c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "grey"
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("Close")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "to": "/login"
    }
  }, [_vm._v("Login")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }