var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', {
    scopedSlots: _vm._u([{
      key: "subHead",
      fn: function () {
        return [_c('sub-visual', {
          attrs: {
            "title": "Download",
            "tabActive": "CI",
            "src": "/images/sub/sv-story-values-ci.png"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('sub-section', {
    staticClass: "page-section--first"
  }, [_c('div', {
    staticClass: "tit-txt-wrap text-center"
  }, [_c('div', {
    staticClass: "tit-wrap"
  }, [_c('h2', {
    staticClass: "tit"
  }, [_vm._v("Group for the Popularization of "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("Domestic Implants")])]), _c('p', {
    staticClass: "tit-txt"
  }, [_vm._v("CI is a company specializing in the production of implant products that Dentium's top quality, "), _c('br', {
    staticClass: "d-none d-md-block"
  }), _vm._v("top dental professionals participate in and produce.")])]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('v-img', {
    attrs: {
      "max-width": "1200",
      "src": "/images/sub/story/values/ci-img.png"
    }
  })], 1)]), _c('sub-section', {
    staticClass: "pt-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Corporate Identity")])]), _c('div', {
    staticClass: "img-wrap"
  }, [_c('v-img', {
    attrs: {
      "max-width": "1200",
      "src": "/images/sub/story/values/ci-img2.jpg"
    }
  })], 1), _c('div', {
    staticClass: "mt-40 mt-md-60"
  }, [_c('div', {
    staticClass: "v-btn--group"
  }, [_c('v-btn', {
    staticClass: "w-160px",
    attrs: {
      "small": "",
      "outlined": "",
      "download": "",
      "href": "/file/dentium-logo.ai",
      "color": "grey-d"
    }
  }, [_vm._v("AI Download"), _c('i', {
    staticClass: "icon icon-down ml-6"
  })]), _c('v-btn', {
    staticClass: "w-160px",
    attrs: {
      "small": "",
      "outlined": "",
      "download": "",
      "href": "/file/dentium-logo.png",
      "color": "grey-d"
    }
  }, [_vm._v("PNG Download"), _c('i', {
    staticClass: "icon icon-down ml-6"
  })])], 1)])]), _c('sub-section', {
    staticClass: "page-section--last pt-0"
  }, [_c('div', {
    staticClass: "tit-wrap tit-wrap--hyphen"
  }, [_c('h2', {
    staticClass: "tit tit--xs"
  }, [_vm._v("Color System")])]), _c('div', {
    staticClass: "font-size-12 font-size-md-14 grey-a--text mb-8 mb-md-10"
  }, [_c('v-row', {
    staticClass: "mx-n10 mx-lg-n20"
  }, [_c('v-col', {
    staticClass: "px-10 py-0 px-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_vm._v("Primary")]), _c('v-col', {
    staticClass: "d-none d-lg-block px-10 py-0 px-lg-20 ml-n1",
    attrs: {
      "cols": "auto"
    }
  }), _c('v-col', {
    staticClass: "px-10 py-0 px-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_vm._v("Secondary")]), _c('v-col', {
    staticClass: "d-none d-lg-block px-10 py-0 px-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }), _c('v-col', {
    staticClass: "d-none d-lg-block px-10 py-0 px-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }), _c('v-col', {
    staticClass: "d-none d-lg-block px-10 py-0 px-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  })], 1)], 1), _c('v-row', {
    staticClass: "ma-n10 ma-lg-n20"
  }, [_c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "primary"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-14 mt-md-20"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text"
  }, [_vm._v("Light Green")]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "grey-6--text"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("CMYK")]), _vm._v("34 / 6 / 95 / 0")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("RGB")]), _vm._v("194 / 213 / 0")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("HEX")]), _vm._v("#c2d500")])])])], 1), _c('v-col', {
    staticClass: "d-none d-lg-block pa-10 pa-lg-20 ml-n1",
    attrs: {
      "cols": "auto"
    }
  }, [_c('v-divider', {
    attrs: {
      "vertical": ""
    }
  })], 1), _c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "secondary"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-14 mt-md-20"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text"
  }, [_vm._v("Green")]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "grey-6--text"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("CMYK")]), _vm._v("46 / 11 / 89 / 0")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("RGB")]), _vm._v("162 / 196 / 56")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("HEX")]), _vm._v("#a2c438")])])])], 1), _c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "grey-90"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-14 mt-md-20"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text"
  }, [_vm._v("Light Gray")]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "grey-6--text"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("CMYK")]), _vm._v("50 / 41 / 39 / 0")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("RGB")]), _vm._v("144 / 144 / 144")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("HEX")]), _vm._v("#909090")])])])], 1), _c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "grey-6"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-14 mt-md-20"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text"
  }, [_vm._v("Dark Gray")]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "grey-6--text"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("CMYK")]), _vm._v("67 / 59 / 56 / 6")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("RGB")]), _vm._v("102 / 102 / 102")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("HEX")]), _vm._v("#666666")])])])], 1), _c('v-col', {
    staticClass: "pa-10 pa-lg-20",
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": ""
    }
  }, [_c('v-sheet', {
    attrs: {
      "color": "grey-16"
    }
  }, [_c('v-responsive', {
    attrs: {
      "aspect-ratio": 1 / 1
    }
  })], 1), _c('div', {
    staticClass: "mt-14 mt-md-20"
  }, [_c('div', {
    staticClass: "font-size-16 font-size-md-18 font-weight-medium grey-16--text"
  }, [_vm._v("Black")]), _c('div', {
    staticClass: "font-size-14 font-size-md-16 mt-10 mt-md-14"
  }, [_c('div', {
    staticClass: "grey-6--text"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("CMYK")]), _vm._v("86 / 81 / 81 / 68")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("RGB")]), _vm._v("22 / 22 / 22")]), _c('div', {
    staticClass: "grey-6--text mt-2"
  }, [_c('span', {
    staticClass: "grey-a--text pr-6 pr-md-10"
  }, [_vm._v("HEX")]), _vm._v("#161616")])])])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }